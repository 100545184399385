<template>
  <div />
</template>

<script>
export default {
  name: "SubscriptionProductTypeDialog",
  computed: {
    subscriptionProductType() {
      return this.$store.getters["login/subscriptionProductType"];
    },
    canAccessPage() {
      const routeHasProductTypes = !!this.$route.meta?.productTypes;

      if (!routeHasProductTypes) {
        return true;
      }

      return (
        this.$route.meta.productTypes &&
        this.subscriptionProductType &&
        this.$route.meta.productTypes.some(
          (productType) => productType === this.subscriptionProductType
        )
      );
    },
  },
  created() {
    if (!this.canAccessPage) {
      this.$warningAlert({
        title: "Você não pode acessar essa página",
        message:
          "Você não tem nenhum plano contratado ou seu plano não suporta essa funcionalidade. Faça o upgrade do seu plano ou contrate um agora mesmo!",
      });
      this.$router.push({ name: "subscriptions.plans" });
    }
  },
};
</script>

<style scoped></style>
